.c-page--inner {
	padding: 115px 0 85px;
	> .row {
		justify-content: space-between;
		max-width: 1440px;
		position: relative;
	}
	@media #{$sm-max} {
		padding: 75px 0;
	}
}

.c-page--content {
	@include columns(8);
	h1, h2, h3, h4, h5, h6 {
		color: $blue;
		padding-bottom: 15px;
	}
	@media #{$md-max} {
		padding-right: 30px;
		padding-left: 30px;
		width: 100%;
	}
}

.c-page--content--inner {
	margin: 0 auto;
	width: 100%;
	@media #{$md-min} {
		max-width: 635px;
	}
}

.c-page--sidebar {
	@include columns(4);
	padding-right: 0;
	@media #{$md-max} {
		margin-top: 50px;
		padding-left: 0;
		width: 100%;
	}
}

.c-page--sidebar--list {
	list-style: none;
	padding: 0;
	> li {
		background: $blue;
		border-radius: 5px;
		padding: 60px 65px 55px;
		&:nth-of-type(even) {
			background: $grey;
		}
		+ li {
			margin-top: 9px;
		}
	}
	h2, p {
		color: white;
	}
	h2 {
		padding-bottom: 15px;
	}
	.button {
		margin-top: 10px;
	}
	@media #{$lg-max} {
		> li {
			padding: 40px 45px 35px;
		}
	}
}


// ----- full width
.c-full {
	padding: 115px 0 85px;
	@media #{$sm-max} {
		padding: 75px 0;
	}
}

.c-full--content {
	padding-right: 15px;
	padding-left: 15px;
	width: 100%;
	@media #{$md-max} {
		padding-right: 30px;
		padding-left: 30px;
	}
	h1, h2, h3, h4, h5, h6 {
		color: $blue;
		padding-bottom: 15px;
	}
}