.c-img-bg-callout {
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: flex-end;
	min-height: 440px;
	padding: 55px 0;
	text-align: center;
	h1 {
		color: white;
		padding-bottom: 20px;
	}
	@media (min-width:1600px){
		min-height: 650px;
	}
	@media (min-width:2200px){
		min-height: 750px;
	}
}