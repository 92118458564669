// home banner
.c-banner-home {
	// background-position: center;
	background-position: right top;
	background-repeat: no-repeat;
	background-size: cover;
	// min-height: 855px;
	// min-height: calc(100vh - 89px);
	min-height: calc(100vh - 150px);
	// padding-top: 350px;
	padding-top: 300px;
	padding-bottom: 25px;
	// padding-bottom: 150px;
	.admin-bar & {
		// min-height: calc(100vh - 121px);
		min-height: calc(100vh - 182px);
	}
	h1, p {
		color: white;
	}
	h1 {
		padding-bottom: 35px;
	}
	@media #{$lg-max} {
		.columns-7 {
			@include resized-columns(8);
		}
	}
	@media #{$md-max} { 
		padding-top: 300px;
		.columns-7 { 
			@include resized-columns(10);
		}	
	}
	@media #{$sm-max} {
		padding-top: 200px;
		.columns-7 {
			width: 100%;
		}
	}
	@media #{$xs-max} {
		padding-top: 150px;
	}
}

// inside banner
.c-banner {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 425px;
}

// banner bottom
.c-banner-bottom {
	background: $grey;
	padding: 30px 0 23px;
	@media #{$sm-max} {
		[class*=columns-] {
			width: 100%;
		}
	}
}

// home banner bottom 
.c-banner-home-bottom {
	background: white;
	margin-bottom: -6px;
	padding: 1px 0 0;
}
.c-banner-home-bottom--inner {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
	padding: 0;
	li {
		margin: 16px;
	}
}

.c-banner-bottom--contact {
	list-style: none;
	padding: 0;
	li {
		display: inline-block;
		+ li {
			margin-left: 15px;
		}
	}
	a {
		@include domine();
		@include font-size(26);
		text-decoration: none;
		color: white;
		&:hover {
			color: $blue;
		}
	}
	i {
		color: $blue;
	}
	@media #{$sm-max} {
		text-align: center;
	}
	@media #{$xs-max} {
		> li {
			display: block;
			+ li {
				margin-top: 5px;
				margin-left: 0;
			}
		}
	}
}

.c-banner-bottom--social {
	list-style: none;
	padding: 0;
	position: relative;
	top: -5px;
	text-align: right;
	li {
		display: inline-block;
		+ li {
			margin-left: 10px;
		}
	}
	a {
		@include font-size(26);
		text-decoration: none;
		color: white;
		&:hover {
			color: $blue;
		}
	}
	@media #{$sm-max} {
		margin-top: 10px;
		text-align: center;
	}
}