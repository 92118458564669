.comment-list {
  //@include list-unstyled;
}
.comment-list ol {
  list-style: none;
}
.comment-form p {
  @extend .form-group !optional;
}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
  @extend .form-control !optional;
}
.comment-form input[type="submit"] {
  @extend .btn !optional;
  @extend .btn-primary !optional;
}
