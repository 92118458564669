.c-blog--cats {
	list-style: none;
	margin-top: 15px;
	margin-bottom: 90px;
	padding: 0;
	@media #{$xs-max} {
		margin-bottom: 45px;
	}
	> li {
		display: inline-block;
		margin-right: 20px;
		margin-bottom: 10px;
		vertical-align: middle;
		&.current-cat {
			> a {
				color: $blue;
				border-bottom: 3px solid $blue;
				padding-bottom: 4px;
			}
		}
		&:hover {
			> a {
				color: $blue;
			}
			> .children {
				opacity: 1;
				visibility: visible;
			}
		}
		> a {
			@include font-size(18);
			font-weight: 500;
			letter-spacing: 1.1px;
			text-transform: uppercase;
		}
	}
	a {
		text-decoration: none;
	}
	.children {
		@include ease;
		background: white;
		list-style: none;
		margin: 0;
		opacity: 0;
		padding: 20px;
		position: absolute;
		text-align: left;
		transform: translateX(-20px);
		visibility: hidden;
		z-index: 998;
		li {
			line-height: 1;
			max-width: 200px;
			+ li {
				margin-top: 7px;
			}
		}
		a {
			@include font-size(18);
			letter-spacing: 0.4px;
			&:hover {
				color: $blue;
			}
		}
	}
}

.c-blog--single {
	+ .c-blog--single {
		margin-top: 50px;
		// @media #{$xs-max} {
		// 	margin-top: 50px;
		// }
	}
}

.c-blog--single--img {
	display: inline-block;
	margin-bottom: 15px;
	img {
		border-radius: 5px;
	}
}

.s-pagination {
	list-style: none;
	margin-top: 80px;
	// margin-top: 165px;
	// padding: 0 170px;
	padding: 0;
	position: relative;
	text-align: center;
	@media #{$xs-max} {
		margin-top: 80px;
		// padding: 0;
	}
	li {
		@include font-size(18);
		display: inline-block;
		vertical-align: middle;
		&.current {
			background: $blue;
			border-radius: 50%;
			color: white;
			height: 24px;
			position: relative;
			width: 24px;
			span {
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
	}
	a {
		text-decoration: none;
		&:hover {
			color: $blue-dark;
		}
	}
}

.s-pagination--item {
	font-weight: 600;
	margin: 0 10px;
}

.s-pagination--prev,
.s-pagination--next {
	margin: 0 10px;
	// position: absolute;
	a {
		color: $blue;
		display: inline-block;
		font-weight: 700;
		position: relative;
		text-transform: uppercase;
		// &:before {
		// 	background: url(../images/pagination-arrow.png) no-repeat;
		// 	content: '';
		// 	height: 20px;
		// 	position: absolute;
		// 	top: 1px;
		// 	width: 11px;
		// }
		&:hover {
			color: $blue-dark;
		}
	}
	@media #{$xs-max} {
		display: block !important;
		position: relative;
		text-align: center;
	}
}

.s-pagination--prev {
	left: 0;
	a {
		// padding-left: 30px;
		// &:before {
		// 	left: 0;
		// }
	}
	@media #{$xs-max} {
		margin-bottom: 30px;
	}
}

.s-pagination--next {
	right: 0;
	a {
		// padding-right: 30px;
		// &:before {
		// 	right: 0;
		// 	transform: rotate(180deg);
		// }
	}
	@media #{$xs-max} {
		margin-top: 30px;
	}
}


.c-single--img {
	margin-top: 15px;
	margin-bottom: 30px;
	img {
		border-radius: 5px;
	}
}