.c-pre-footer {
	background: $blue;
	padding: 95px 0 80px;
	> .row {
		max-width: 1000px;
	}
	h1 {
		color: white;
	}
	@media #{$sm-max} {
		padding: 75px 0;
	}
}

.c-pre-footer--left {
	width: 64%;
	@media #{$sm-max} {
		width: 100%;
	}
}

.c-pre-footer--right {
	width: 36%;
	@media #{$sm-max} {
		margin-top: 50px;
		width: 100%;
	}
}

.c-footer {
	background: $grey;
	padding: 50px 0 40px;
	> .row {
		justify-content: space-between;
	}
}

.c-footer--logo {
	background: url(../images/logo.svg) no-repeat center;
	background-size: contain;
	display: block;
	// height: 169px;
	height: 85px;
	max-width: 100%;
	text-indent: -9999px;
	// width: 286px;
	width: 143px;
	@media #{$md-max} {
		margin: 0 auto;
	}
}

.c-footer--col-1 {
	// width: 31%;
}

.c-footer--col-2 {
	margin-top: 10px;
	// width: 33%;
}

.c-footer--col-3 {
	margin-top: 5px;
	// width: 19%;
}

.c-footer--col-4 {
	margin-top: 5px;
	// width: 17%;
}

.c-footer--col-1,
.c-footer--col-2,
.c-footer--col-3,
.c-footer--col-4 {
	@media #{$md-max} {
		margin-top: 0;
		margin-bottom: 30px;
		text-align: center;
		width: 100%;
	}
}


.c-footer--col-5 {

}

.c-footer--contact {
	list-style: none;
	padding: 0;
	li {
		+ li {
			margin-top: 5px;
		}
	}
	a {
		@include domine();
		@include font-size(26);
		text-decoration: none;
		color: white;
		&:hover {
			color: $blue;
		}
	}
	i {
		color: $blue;
	}
}

.c-footer--address {
	margin-top: 25px;
	position: relative;
	i {
		color: $blue;
		position: absolute;
		top: 0;
		left: 0;
	}
	p {
		@include font-size(14);
		color: white;
		line-height: 1.57em;
		padding-left: 20px;
	}
	@media #{$md-max} {
		display: inline-block;
	}
}

.c-footer--menu-title {
	color: white;
	padding-bottom: 10px;
	text-transform: uppercase;
}

.c-footer--menu {
	list-style: none;
	padding: 0;
	a {
		@include font-size(14);
		color: white;
		text-decoration: none;
		&:hover {
			color: $blue;
		}
	}
}

.c-footer--img {
	margin-top: 25px;
}

.c-footer--legal {
	text-align: center;
	p {
		@include font-size(14);
		color: white;
		display: inline-block;
		padding: 0;
		position: relative;
		top: 1px;
		vertical-align: middle;
	}
}

.menu-legal-menu-container {
	display: inline-block;
	vertical-align: middle;
}

#footer-menu {
	list-style: none;
	padding: 0;
	li {
		display: inline-block;
		margin-left: 7px;
		padding-left: 12px;
		position: relative;
		&:before {
			background: white;
			content: '';
			position: absolute;
			top: 4px;
			bottom: 1px;
			left: 0;
			width: 1px;
		}
	}
	a {
		@include font-size(14);
		color: $white;
		text-decoration: none;
	}
}