// Set base font size
$rem-base: 16px !default;
// Set line height ratio base. Accepts px, em, rem or int values
$line-height-base: 26px !default;
// Vertical rhythm ratio
$type-rhythm-ratio: 1.14 !default;
// Set Grid Column Count
$grid-columns: 12 !default;
// Set Grid Gutter (percentage)
$grid-gutter: 4 !default;
// Set Grid Padding (as px)
$grid-padding: 15px !default;
// Set Grid Max Width (as px)
$grid-max-width: 1200px !default;



// Colors
$black : #000;
$offblack : #313131;
$grey : #4c4c4c;

$white : #fff;
$off-white: #f0f0f1;

$yellow : #ffcf00;

$blue : #5ecaf4;
$blue-dark : #004780;

$red : #ed1c24;

$maintext: $grey;



// MEDIA QUERIES

// Query Sizes
// ----- Media Queries
$max-size_extra_extra_small: '549px';
$max-size_extra_small: '767px';
$max-size_small: '899px';
$max-size_medium: '1023px';
$max-size_large: '1149px';

$xxs-max: "(max-width: " $max-size_extra_extra_small " )" !default;
$xs-max: "(max-width: " $max-size_extra_small " )" !default;
$sm-max: "(max-width: " $max-size_small " )" !default;
$md-max: "(max-width: " $max-size_medium " )" !default;
$lg-max: "(max-width: " $max-size_large " )" !default;

$min-size_extra_extra_small: '550px';
$min-size_extra_small: '768px';
$min-size_small: '900px';
$min-size_medium: '1024px';
$min-size_large: '1150px';

$xxs-min: "(min-width: " $min-size_extra_extra_small " )" !default;
$xs-min: "(min-width: " $min-size_extra_small " )" !default;
$sm-min: "(min-width: " $min-size_small " )" !default;
$md-min: "(min-width: " $min-size_medium " )" !default;
$lg-min: "(min-width: " $min-size_large " )" !default;



// example usage
// @media #{$md-min} {
// 	width: 100%;
// }