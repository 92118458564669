// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  //margin: ($line-height-computed / 2) auto;
  height: auto;
  margin-bottom: 25px;
}
.alignleft,
.alignright {
  //margin-bottom: ($line-height-computed / 2);
  height: auto;
}

.alignleft {
  display: inline;
  float: left;
  // margin-right: 1.5em;
  margin-right: 25px;
  margin-bottom: 25px;
}

.alignright {
  display: inline;
  float: right;
  margin-bottom: 25px;
  margin-left: 25px;
}

.aligncenter {
  clear: both;
}


@media #{$sm-min} {

  // Only float if not on an extra small device
  .alignleft {
    float: left;
    //margin-right: ($line-height-computed / 2);
  }
  .alignright {
    float: right;
    //margin-left: ($line-height-computed / 2);
  }
}

// Captions
.wp-caption {
}
.wp-caption-text {
  //padding: $thumbnail-caption-padding;
}

// Text meant only for screen readers
.screen-reader-text {
}
