.c-listing-text {
	padding: 75px 0;
	> .row {
		justify-content: space-between;
	}
	h1 {
		color: $blue;
		padding-top: 13px;
	}
	.button {
		margin-top: 25px;
	}
	@media #{$lg-max} {
		.columns-4 {
			@include resized-columns(5);
		}
	}
	@media #{$sm-max} {
		.columns-7 {
			width: 100%;
		}
		.columns-4 {
			@include resized-columns(8);
			margin-top: 50px;
		}
	}
	@media #{$xs-max} {
		.columns-4 {
			@include resized-columns(10);
		}
	}
	@media #{$xxs-max} {
		.columns-4 {
			width: 100%;
		}
	}
}

.c-listing-text--listing {
	border: 1px solid $off-white;
	border-radius: 10px;
	overflow: hidden;
}

.c-listing-text--listing--img {
	position: relative;
	img {
		width: 100%;
	}
	span {
		@include domine;
		@include font-size(14);
		background: $blue;
		border-radius: 5px;
		color: white;
		display: inline-block;
		padding: 7px 12px;
		position: absolute;
		bottom: 20px;
		left: 20px;
		text-transform: uppercase;
	}
}

.c-listing-text--listing--info {
	padding: 15px 20px 15px;
	h5 {
		padding-bottom: 10px;
	}
	h6 {
		color: $blue;
		padding-bottom: 5px;
	}
	p {
		@include font-size(14);
		padding-bottom: 10px;
	}
	ul {
		list-style: none;
		padding: 0;
		li {
			@include font-size(14);
			display: inline-block;
			padding-right: 10px;
			margin-bottom: 10px;
			padding-left: 35px;
			&.bed {
				background: url(../images/icon-bed.png) no-repeat left center;
			}
			&.bath {
				background: url(../images/icon-bath.png) no-repeat left center;
			}
			&.area {
				background: url(../images/icon-area.png) no-repeat left center;
			}
		}
	}
}