.c-bucket-callout {
	margin-top: 9px;
}

.c-bucket-callout--list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	list-style: none;
	padding: 0;
	> li {
		background: $blue;
		border-radius: 5px;
		padding: 60px 65px 55px;
		width: calc((100% / 3) - 6px);
		&:nth-of-type(even) {
			background: $grey;
		}
	}
	h2, p {
		color: white;
	}
	h2 {
		padding-bottom: 15px;
	}
	.button {
		margin-top: 10px;
	}
	@media #{$lg-max} {
		> li {
			padding: 40px 45px 35px;
		}
	}
	@media #{$md-max} {
		> li {
			width: 100%;
			+ li {
				margin-top: 9px;
			}
		}
	}
}