.button {
	// @include domine;
	// @include font-size(14);
	// color: white;
	// display: inline-block;
	// padding: 17px 26px;
	// text-decoration: none;
	// &.primary {
	// 	background: $blue;
	// 	&:hover {
	// 		background: $grey;
	// 	}
	// }
	// &.secondary {
	// 	background: $grey;
	// 	&:hover {
	// 		background: $blue;
	// 	}
	// }
	&.primary {
		@include button(blue);
	}
	&.secondary {
		@include button(grey);
	}
}
