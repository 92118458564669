// ----- body styles
body {
	@include font-size(16);
	@include open();
	color: $maintext;
	margin: 0;
	overflow-x: hidden;
	position: relative;
	// &.stop-scrolling {
	// 	height: 100%;
	// 	overflow: hidden;
	// }
}

::selection {
	background: $blue;
	color: white;
}

::-moz-selection {
	background: $blue;
	color: white;
}


// ----- headings

// 36, 400
// 32, 400
// 28, 500, has to be lowercase due to i3 on residences page
// 24, 400
// 20, 600, lower,
// 20, 400, 


h1, h2, h3, h4, h5, h6 {
	clear: both;
	margin: 0;
	a {
		text-decoration: none;
	}
}
h1 {
	@include domine();
	@include font-size(60);
	line-height: 1.11em;
	padding-bottom: 25px;
	@media #{$sm-max} {
		@include font-size(48);
	}
}
h2 {
	@include domine();
	@include font-size(44);
	padding-bottom: 25px;
}
h3 {
	@include domine();
	@include font-size(32);
	padding-bottom: 25px;
}
h4 {
	@include font-size(24);
	padding-bottom: 25px;
}
h5 {
	@include domine();
	@include font-size(18);
	padding-bottom: 25px;
}
h6 {
	@include font-size(18);
	padding-bottom: 25px;
}

/*
60 domine 700
44 domine 700
32 domine 700
24 open 700
18 domine 700
18 open 700

*/


// ----- links
a {
	@include ease;
	color: $blue;
	text-decoration: underline;
}

.read-more {
	@include font-size(18);
	font-weight: 700;
	text-decoration: none;
	&:hover {
		color: $blue-dark;
	}
}


// ----- paragraphs
p {
	@include font-size(16);
	line-height: 1.38em;
	margin: 0;
	padding-bottom: 15px;
}

strong {
	font-weight: 700;
}

em {
	font-style: italic;
}

.entry-content {
	* {
		+ h1, + h2, + h3, + h4, + h5, + h6, + .gform_wrapper {
			padding-top: 40px;
		}
	}
	a {
		color: $blue;
	}
	p {
		word-break: break-word;
	}
}


// ----- images 
img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}


// ----- lists
// ul, ol {
// 	margin: 0;
// 	padding: 0;
// }

ul {
	list-style: disc;
	margin: 0;
	padding: 0 0 0 23px;
}

ol {
	list-style: decimal;
	margin: 0;
	padding: 0;
}

li > ul,
li > ol {
	margin-bottom: 0;
	// margin-left: 1.5em;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0;
}


// ----- and the rest
dfn, cite, em, i {
	font-style: italic;
}

blockquote {
	margin: 0 1.5em;
}

address {
	margin: 0 0 1.5em;
}

pre {
	// background: $color__background-pre;
	// font-family: $font__pre;
	@include font-size(0.9375);
	// line-height: $font__line-height-pre;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code, kbd, tt, var {
	font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}

abbr, acronym {
	cursor: help;
}

mark, ins {
	text-decoration: none;
}

big {
	font-size: 125%;
}

hr {
	// background-color: $maintext;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

figure {
	margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */
}

table {
	margin: 0 0 1.5em;
	width: 100%;
}