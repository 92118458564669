.c-listing-bg {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 140px 0;
	@media #{$lg-max} {
		.columns-6 {
			@include resized-columns(8);
		}
	}
	@media #{$sm-max} {
		padding: 75px 0;
		.columns-6 {
			@include resized-columns(10);
		}
	}
	@media #{$xs-max} {
		.columns-6 {
			width: 100%;
		}
	}
}

.c-listing-bg--inner {
	max-width: 410px;
	padding-right: 15px;
	padding-left: 15px;
	width: 100%;
}

.c-listing-bg--info {
	background: white;
	border-radius: 5px;
	padding: 40px 50px 25px;
	span {
		@include domine;
		@include font-size(14);
		background: $blue;
		border-radius: 5px;
		color: white;
		display: inline-block;
		margin-bottom: 10px;
		padding: 7px 12px;
		text-transform: uppercase;
	}
	h3 {
		padding-bottom: 5px;
	}
	h4 {
		color: $blue;
		padding-bottom: 15px;
	}
	ul {
		list-style: none;
		padding: 0;
		li {
			@include font-size(14);
			display: inline-block;
			padding-right: 20px;
			margin-bottom: 15px;
			padding-left: 35px;
			&.bed {
				background: url(../images/icon-bed.png) no-repeat left center;
			}
			&.bath {
				background: url(../images/icon-bath.png) no-repeat left center;
			}
			&.area {
				background: url(../images/icon-area.png) no-repeat left center;
			}
			&.date {
				background: url(../images/icon-date.png) no-repeat left center;
			}
		}
	}
}

.c-listing-bg--realtor {
	background: white;
	margin-top: 10px;
	border-radius: 0 5px 5px 0;
	overflow: hidden;
}

.c-listing-bg--realtor {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-right: 20px;
	@media #{$xxs-max} {
		// justify-content: center;
	}
}

.c-listing-bg--realtor--left {
	img, h6 {
		display: inline-block;
		vertical-align: middle;
	}
	img {
		margin-right: 15px;
	}
	h6 {
		padding: 0;
	}
	span {
		@include font-size(14);
		@include open();
		display: block;
	}
}

.c-listing-bg--realtor--right {
	@media #{$xxs-max} {
		// padding: 25px 0;
		// width: 100%;
	}
}