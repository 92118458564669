.c-header {
	background-color: transparent;
	margin: 0 auto;
	// padding: 80px 0 45px;
	padding: 40px 0 45px;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	@include ease;
	width: 100%;
	z-index: 1000;
	&:before {
		background-image: linear-gradient(rgba(#4c4c4c,1),rgba(black,0));
		content: '';
		opacity: 1;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		@include ease;
		z-index: -1;
	}
	&.scroll-first {
		background-color: rgba(#1e323a, .9);
		padding: 15px 0;
		&:before {
			opacity: 0;
		}
	}
	&.scroll-second {
		padding: 0;
		&:before {
			opacity: 0;
		}
		> .row {
			align-items: center;
		}
	}
	> .row {
		justify-content: space-between;
	}
	@media #{$md-max} {
		background-color: rgba(#1e323a, .9);
		padding: 0 !important;
		> .row {
			align-items: center;
		}
	}
	.admin-bar & {
		top: 32px;
		@media screen and (max-width: 782px) {
			top: 46px;
		}
	}
}

#wpadminbar {
	@media screen and (max-width: 600px) {
		position: fixed;
	}
}

.c-site-branding {
	width: 316px;
	.scroll-second & {
		@include resized-columns(2);
	}
	@media #{$md-max} {
		@include resized-columns(2);
	}
}

.c-site-logo {
	background: url(../images/logo.svg) no-repeat center top;
	background-size: cover;
	display: inline-block;
	// height: 149px;
	// height: 85px;
	height: 141px;
	text-indent: -9999px;
	transition: none;
	// width: 143px;
	// width: 250px;
	width: 237px;
	.scroll-second & {
		background-size: auto;
		height: 68px;
		transform: scale(0.6);
		width: 60px;
	}
	@media #{$md-max} {
		background-size: auto;
		height: 68px;
		transform: scale(0.6);
		width: 60px;
	}
}

.c-site-navigation {
	/* padding-left: 55px; */
	// width: calc(100% - 316px);
	@include resized-columns(8);
	.scroll-second & {
		position: relative;
		top: -4px;
		// @include resized-columns(10);
	}
	@media (max-width:1300px){
		.scroll-second & {
			padding-right: 90px;
		}
	}
	@media #{$md-max} {
		display: none;
	}
}

.c-site-navigation--upper {
	border-bottom: 2px solid rgba(white,0.7);
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 5px;
	// margin-bottom: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
	.scroll-second & {
		display: none;
	}
	// @media #{$lg-max} {
		// display: none;
		// justify-content: flex-end;
	// }
}

.c-site-navigation--utility-button {
	// background: url(../images/search.svg) no-repeat left center;
	background: white;
	border: 1px solid white;
	color: $grey;
	display: inline-block;
	// margin-bottom: 5px;
	// padding-left: 25px;
	padding: 5px 10px;
	position: relative;
	top: -2px;
	text-decoration: none;
	text-transform: uppercase;
	path {
		fill: $grey;
	}
	&:hover {
		background: transparent;
		color: white;
		path {
			fill: $white;
		}
	}
	svg {
		margin-right: 5px;
		position: relative;
		top: 1px;
		path {
			@include ease;
		}
	}
}

#utility-menu {
	list-style: none;
	margin-bottom: 5px;
	padding: 0;
	text-align: right;
	> li {
		display: inline-block;
		margin-left: 15px;
		@media #{$lg-max} {
			margin-left: 5px;
		}
	}
	a {
		@include font-size(16);
		color: white;
		font-weight: 700;
		text-decoration: none;
		text-transform: uppercase;
		&:hover {
			color: $blue;
		}
	}
	@media #{$lg-max} {
		// display: none;
		// margin-top: 5px;
		width: 100%;
	}
}

.c-site-navigation--lower {
	// border-bottom: 2px solid rgba(white,0.7);
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	// margin-top: 5px;
	// margin-bottom: 10px;
	// margin-bottom: 5px;
	// padding-bottom: 15px;
	// .scroll-second & {
	// 	display: none;
	// }
	// @media #{$lg-max} {
		// display: none;
		// justify-content: flex-end;
	// }
}

.c-header--phone {
	color: white;
	@include font-size(18);
	font-weight: 700;
	text-decoration: none;
	&:hover {
		color: $blue;
	}
}

#primary-menu {
	list-style: none;
	padding: 0;
	text-align: right;
	> li {
		display: inline-block;
		margin-left: 50px;
	}
	a {
		@include font-size(18);
		color: white;
		font-weight: 700;
		text-decoration: none;
		&:hover {
			color: $blue;
		}
	}
	@media (max-width:1300px){
		> li {
			margin-left: 30px;
			&:first-of-type {
				margin-left: 0;
			}
		}
	}
	@media #{$lg-max} {
		> li {
			margin-left: 20px;
		}
	}
}

.c-mobile-trigger {
	@include ease;
	cursor: pointer;
	// height: 37px;
	height: 28px;
	opacity: 0;
	position: absolute;
	top: 50px;
	right: 70px;
	visibility: hidden;
	// width: 40px;
	width: 35px;
	z-index: 1;
	span {
		@include ease(0.3s);
		background: white;
		display: block;
		// height: 5px;
		height: 4px;
		position: absolute;
		width: 100%;
		&:nth-of-type(1) {
			top: 0;
		}
		&:nth-of-type(2) {
			// top: 16px;
			top: 12px;
		}
		&:nth-of-type(3) {
			background: $blue;
			// top: 32px;
			top: 24px;
		}
	}
	&.open {
		span {
			&:nth-of-type(1) {
				// top: 5px;
				top: 12px;
				transform: rotate(-45deg);
			}
			&:nth-of-type(2) {
				// top: -5px;
				transform: rotate(45deg);
			}
			&:nth-of-type(3) {
				background: $grey;
				opacity: 0;
			}
		}
		// span:nth-of-type(3) {
			
		// }
	}
	.scroll-second & {
		opacity: 1;
		top: 22px;
		visibility: visible;
	}
	@media (max-width:1400px){
		right: 30px;
	}
	@media #{$md-max} {
		opacity: 1;
		top: 22px;
		visibility: visible;
	}
}

.c-mobile-menu {
	@include ease;
	background: $blue;
	opacity: 0;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	visibility: hidden;
	&.open {
		opacity: 1;
		visibility: visible;
	}
}

#mobile-nav {
	list-style: none;
	padding: 120px calc((1 * ((100vw - 300px) / 12)) + 40px);
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	li {
		@include ease(1s);
		opacity: 0;
		transition-delay: 0s;
		.open & {
			opacity: 1;
		}
		+ li {
			margin-top: 5px;
		}
	}
	a {
		@include domine;
		@include font-size(36);
		color: white;
		font-weight: 700;
		text-decoration: none;
		&:hover {
			color: $grey;
		}
	}
	@media #{$xs-max} {
		a {
			@include font-size(32);
		}
	}
}