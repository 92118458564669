.c-flat-bg-callout {
	background: $blue;
	border-top: 6px solid white;
	border-bottom: 6px solid white;
	padding: 110px 0 120px;
	> .row {
		justify-content: space-between;
	}
	[class*=columns-] {
		&:first-of-type {
			max-width: 50%;
			width: 550px;
		}
		&:last-of-type {
			max-width: 50%;
			width: 525px;
		}
	}
	h1 {
		color: white;
	}
	p {
		&:last-of-type {
			padding-bottom: 0;
		}
	}
	@media #{$sm-max} {
		padding: 75px 0;
		[class*=columns-] {
			&:first-of-type,
			&:last-of-type {
				max-width: none;
				width: 100%;
			}
			&:last-of-type {
				margin-top: 50px;
			}
		}
	}
}

.c-flat-bg-callout--logos {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -28px;
	padding: 0;
	li {
		padding: 0 28px;
		width: 50%;
		&:nth-of-type(even) {
			text-align: right;
		}
	}
	@media #{$sm-max} {
		li {
			width: auto;
		}
	}
}