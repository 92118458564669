.c-carousel {
	padding: 95px 0 80px;
	text-align: center;
	> .row {
		max-width: 1310px;
	}
	[class*=columns-] {
		padding-right: $gutter;
		padding-left: $gutter;
	}
	h3 {
		padding-bottom: 40px;
	}
	@media #{$sm-max} {
		padding: 75px 0;
	}
}

.c-carousel--slider {
	padding: 0 80px;
	overflow-x: hidden;
	a, img, h4 {
		@include ease;
	}
	a {
		background-color: #f6f7f9;
		color: $maintext;
		display: inline-block;
		margin: 0 0 10px;
		position: relative;
		text-decoration: none;
		
		@media #{$sm-min} {
			transform: translateY(45px);
		}
	}
	img {
		width: 100%;
	}
	.c-carousel--inner {
		padding: 30px 55px 40px;
	}
	h4 {
	}
	p {
		font-weight: 400;
	}
	.slick-center {
		position: relative;
		z-index: 1;
		a {
			background-color: white;
			box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.05);
			// margin: 0 -20px 10px;
			transform: translateY(0);
			z-index: 1;
		}
		img {
		}
		.c-carousel--inner {
		}
		h4 {
			@include font-size(24);
		}
	}
	@media #{$md-max} {
		padding: 0 65px;
		.c-carousel--inner {
			padding: 25px 25px 20px;
		}
	}
	@media #{$sm-max} {
		padding: 0 35px;
		.c-carousel--inner {
			padding: 25px 20px 20px;
		}
	}
}

.slick-arrow {
	background: url(../images/arrow.png) no-repeat center transparent;
	background-size: cover;
	border: none;
	height: 53px;
	position: absolute;
	// top: calc(50% - 26px);
	top: 240px;
	text-indent: -9999px;
	width: 53px;
	&:focus {
		outline: none;
	}
	@media #{$sm-max} {
		height: 26px;
		width: 26px;
	}
}

.slick-prev {
	left: 0;
	transform: rotate(180deg);
}

.slick-next {
	right: 0;
}