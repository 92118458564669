@mixin ease ($dur:0.5s) {
	-webkit-transition: $dur ease;
	-moz-transition: $dur ease;
	-o-transition: $dur ease;
	transition: $dur ease;
}

////////////////////////////////
//////       FONT        ///////
////////////////////////////////

// @mixin font($font:main, $weight: 400, $style:normal) {
// 	@if($font == main){
// 		@include font("Montserrat", $weight, $style);
// 		// Regular  	400
// 		// Bold	   		700
// 	}
// 	@else if($font == alt){
// 		@include font("proxima", $weight, $style);
// 	}
// }

// @mixin font-select( $font:'Montserrat', $weight:400, $style:normal ) {
// 	font-family: $font , sans-serif;
// 	font-weight: $weight;
// 	font-style: $style;
// }

@mixin open($weight:300, $style:normal) {
	font-family: 'Open Sans', sans-serif;
	font-style: $style;
	font-weight: $weight;
}

@mixin domine($weight:700, $style:normal) {
	font-family: 'Domine', serif;
	font-style: $style;
	font-weight: $weight;
}

@mixin font-size( $fontSize:16, $baseSize: 16) {
	font-size: #{$fontSize}px;
	font-size: #{($fontSize/$baseSize)}rem;
}


////////////////////////////////////
//////         BUTTON        ///////
////////////////////////////////////

@mixin button( $color:blue, $size:regular) {
	@include buttonBase();
	@include buttonSize($size);
	@include buttonColor($color);
}

@mixin buttonBase() {

	// border-radius: 6px;
	// border: none;
	// color: white;
	// cursor: pointer;
	// display: inline-block;
	// letter-spacing: 0.05em;
	// line-height: 1.3;
	// margin-top: 25px;
	// position: relative;

	// text-transform: uppercase;
	// text-decoration: none;

	@include ease;
	@include domine;
	// @include font-size(14);
	border: none;
	color: white;
	display: inline-block;
	// padding: 17px 26px;
	text-decoration: none;

	// &:active,
	// &:focus,
	// &:hover {
	// 	text-decoration: none;
	// 	color: white;
	// }
}

@mixin buttonSize($size) {
	@if $size == regular {
		@include font-size(14);
		padding: 17px 26px;
	}
	// } @else if $size == large {
	// 	@include font-size(16);
	// 	padding: 15px 30px;
	// }
}

@mixin buttonColor($color) {
	@if $color == blue {
		background-color: $blue;
		&:hover {
			background-color: $grey;
		}
		&:active,
		&:focus {
			background-color: $grey;
		}
	} @else if $color == grey {
		background-color: $grey;
		&:hover {
			background-color: $blue;
		}
		&:active,
		&:focus {
			background-color: $blue;
		}
	}
}


////////////////////////////////////
//////        TRIANGLE       ///////
////////////////////////////////////

@mixin triangle($direction, $color, $height: 10px, $width: 10px) {
	width: 0;
	height: 0;
	display: block;
	border-style: solid;

	@if ($direction == up) {
		border-width: 0 $height $width $height;
		border-color: transparent;
		border-bottom-color: $color;
	} @else if ($direction == down) {
		border-width: $width $height 0 $height;
		border-color: transparent;
		border-top-color: $color;
	} @else if ($direction == left) {
		border-width: $height $width $height 0;
		border-color: transparent;
		border-right-color: $color;
	} @else if ($direction == right) {
		border-width: $height 0 $height $width;
		border-color: transparent;
		border-left-color: $color;
	}

}

////////////////////////////////
//////// CENTERING THINGS /////
///////////////////////////////

@mixin center() {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@mixin horizontalCenter() {
	left: 50%;
	transform: translateX(-50%);
}

@mixin verticalCenter() {
	top: 50%;
	transform: translateY(-50%);
}




////////////////////////
///// RADIO BUTTON /////
////////////////////////



@mixin radio-button( $radius:4px, $border-color: #ccc, $bullet-color: #000 ) {
	position: relative;

	input {
		width: 23px;
		height: 23px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0;
	}
	label {
		display: block;
		padding-left: 30px;

		&:before,
		&:after {
			content: '';
			display: block;
			border-radius: $radius;
			border: solid 1px $border-color;
			height: 23px;
			width: 23px;
			position: relative;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			background: white;
			cursor: pointer;
		}
		&:after {
			background: $bullet-color;
			border: none;
			left: 6px;
			display: none;
			height: 11px;
			width: 11px;
		}
	}
	:checked + label {
		&:after {
			display: block;
		}
	}
}